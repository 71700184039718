import { defineStore } from 'pinia'
import axios from 'axios'
export const useSubscriptionStore = defineStore('subscription', {
  state: () => ({ 
    planData:{
      plan:{},
      user_settings:{}
    },
    plans:[],
    plan_:{
      plan:{},
      card:'',
      business_id:'',
      action:''
    }
   }),
  actions: {
    async planDetail(id) {
      const resp = await axios.get('plan_detail/'+id)
      if (resp.data.status === 'success') {
        this.planData = resp.data.data
        return true
      }
    },
    async getPlansList(){
      const response = await axios.get('subscription_plans')
      if (response.data.status == 'success') {
        this.plans = response.data.data
        return true
      }
    },
    async updateAutoRenewStatus(status) {
      const resp = await axios.put("update_auto_renew_setting",{status:status})
      if (resp.data.status == 'success') {
        this.planData.user_settings.auto_renew_plan = status
      }
    },
    async updateAutoRenewCard(card_id) {
      const resp = await axios.put("update_auto_renew_card",{card:card_id}).catch(error => {return error.response})
      return resp.data
    },
    async updatePlan() {
      //console.log(this.plan_)
      const resp = await axios.put("update_plan",this.plan_).catch(error => {return error.response})
      return resp.data
    },
    clearPlan(){
      this.plan_={
        plan:{},
        card:'',
        business_id:'',
        action:''
      }
    }
  }
})